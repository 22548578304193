import React from "react"
import ProductSheet from "../images/cdp_cover.png"


// A simple form which re-directs to download_thanks after submitting the form.
// The form is connected to the email service "Basin" which is free for up to 1000 emails per month.

const Cta = () => {      
        
  return (      
                <div className="container grid md:grid-cols-3 relative w-3/4 p-8 bg-gray-800 rounded-t-2xl">
                    <figure>
                    <div className="md:pl-24"><img src={ProductSheet} alt="Toristy Product Sheet" className="w-48" /></div>
                    </figure>
                    <figure className="pt-2">
                    <div className="text-xl text-red-400">Join Steve Things</div>
                    <div className="pt-4 pr-4 text-white">And get Unmask your customers - free 
                    <ul className="list-disc list-inside text-white pt-2">
                        <li>AI/ML in the context of CDPs</li>
                        <li>How a CDP works in 4 industries</li>
                        <li>56 tools compared</li> 
                        <li>Tool deep dive (6 tools)</li>
                        <li>6 case studies (one each tool)</li>
                        <li>A methodology to select a CDP</li>
                    </ul>
                    </div>
                    <div className="text-l pt-2 text-red-400">Fill in the form to join today</div>
                    </figure>
                    <figure>
                    <div className="pl-2">
                        <span className="text-xs text-white">* Required field</span>
                        <form method="post" action="https://usebasin.com/f/455cee9109d5">
                            <input type="hidden" name="Jackson.fi" value="Whitepaper Downloaded"></input>
                            <div className="space-x-4 pb-2">
                            <label htmlFor="firstName" className="text-white">First Name* <input className="rounded w-full text-black" type="text" name="firstName" /></label>
                            </div>
                            <div className="space-x-4 pb-2">
                            <label htmlFor="lastName" className="text-white">Last Name* <input className="rounded w-full text-black" type="text" name="lastName" /></label>
                            </div>
                            <div className="col-span-1 pb-2">
                            <label htmlFor="email" className="text-white">Email* <input className="rounded w-full text-black" type="email" name="email" />
                            </label>
                            </div>
                            <div className="col-span-1 pb-2">
                            <button className="bg-black text-center text-white py-2 px-4 rounded-lg focus:ring focus:ring-blue-300 hover:bg-red-400" 
                            type="submit">Get the download</button>
                            </div>
                        </form>
                    </div>
                    </figure>
                </div>

    )
}
export default Cta