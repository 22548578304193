import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { SEO } from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import IdxHeadings from "../components/sg-headings"
import Cta from "../components/call_to_action"

// Add Page Head component and pass in SEO variables from GraphQL
export const Head = ({ data }) => {
  const title = data.strapiProductIndex.headline 
  const description = data.strapiGlobal.siteDescription
  const shareImage = getImage(data.strapiProductIndex.heroImage.localFile)
  return (
    <SEO title={title} description={description} image={shareImage} />
  )
}
// Page variables
export default function Products ( { data } ) {
  const products = data.allStrapiProduct.nodes
  const background = getImage(data.strapiProductIndex.backgroundImage.localFile)
  const headline = data.strapiProductIndex.headline
  const shortDesc = data.strapiProductIndex.shortDesc
  const heroHeadline = data.strapiProductIndex.heroHeadline
  const heroDesc = data.strapiProductIndex.heroText
  const heroImage = getImage(data.strapiProductIndex.heroImage.localFile)
  const heroURL = data.strapiProductIndex.heroURL
// Return layout
  return (
    <Layout>
      <div className="relative w-full h-full bg-gradient-to-r from-gray-800 to-red-700">
            <BgImage 
              image={background} 
              alt="Smoke drifting right" 
              className="absolute h-96 bg-center object-cover mix-blend-overlay" />
                <div className="container absolute top-0 left-1/2 -translate-x-1/2">
                  <IdxHeadings title={headline} description={shortDesc} />
                </div>  
      </div> 
       <div className="container">
          <div className="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
            <div className="md:7/12 lg:w-6/12"><h2 className="p-1 text-2xl text-gray-900 font-bold md:text-4xl">{heroHeadline}</h2>
            <div className="p-2 space-y-4">{heroDesc}</div>
          </div>
          <div className="md:5/12 lg:w-5/12"><GatsbyImage image={heroImage} className="w-1/2" alt="Cult Of Analytics by Steve Jackson" /></div>
        </div>
        <a href={heroURL} className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-red-400 hover:bg-slate-900 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900">
                Buy the book on Amazon
        </a>
      </div>
      <div className="container pt-12 space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
            <div className="md:7/12 lg:w-6/12">
              <h2 className="p-1 text-2xl text-gray-900 font-bold md:text-4xl">More published eBooks you can buy directly</h2></div>
      
      </div>
      <div className="container mt-12 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
        {products.map(product => (
          <Link to={"/product/" + product.slug} key={product.id} >
          <div className="overflow-hidden shadow rounded-3xl transition-shadow-2xl hover:shadow-lg">
          <GatsbyImage className="h-48" image={product.productHeroImage.localFile.childImageSharp.gatsbyImageData} alt="Books Steve Jackson"/>
              <h3 className="px-4 py-1 font-bold text-neutral-700 hover:text-red-400">{ product.productName }</h3>
              <p className="px-4 py-1 line-clamp-5 mt-2 text-neutral-600">{ product.shortDesc }</p>
          </div>
          </Link>
        ))}
      </div>
      <div className="container mt-8"></div>
      <Cta />
    </Layout>
  )

}
// GraphQL query

export const query = graphql`
query ProductCard {
  allStrapiProduct {
    nodes {
      productName
      shortDesc
      id
      slug
      productHeroImage {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
  strapiGlobal {
    siteDescription
  }
  strapiProductIndex {
    heroHeadline
    heroImage {
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    heroText
    heroURL
    headline
    shortDesc
    backgroundImage {
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
}
`
